<script setup lang="ts">
import { useForm } from 'vee-validate'
import { storeToRefs } from 'pinia'
import { EContent } from 'auth/enums/dialog-contents'
import { signUpSecondSchema } from 'auth/validation-schema'
import IconLogo from '@/assets/images/logo.svg'

const props = defineProps<{
  type: 'brand' | 'creator'
}>()
const oppositeType = computed(() =>
  props.type === 'brand' ? 'Creator' : 'Brand',
)
const oppositeContent = computed(() =>
  props.type === 'brand'
    ? EContent.SIGN_UP_SECOND_CREATOR
    : EContent.SIGN_UP_SECOND_BRAND,
)
const emit = defineEmits<{
  (e: 'switch', value: EContent): void
}>()

const { defineComponentBinds, handleSubmit, errors, submitCount } = useForm({
  validationSchema: signUpSecondSchema,
})
const username = defineComponentBinds('username')
const newPassword = defineComponentBinds('newPassword')
const passwordConfirm = defineComponentBinds('passwordConfirm')
const { isSubmitted } = useValidation(submitCount)

const authStore = useAuthStore()
const { isDialogVisible } = storeToRefs(authStore)
const signUp = handleSubmit(async (values) => {
  const customer = await authStore.register(
    values.newPassword,
    values.username,
    props.type === 'brand' ? 'buyer' : 'creator',
  )

  if (customer) {
    emit('switch', EContent.SIGN_UP_SUCCESS)
    useKlaviyo('sign-up', {
      customerEmail: customer.email,
      customerType: props.type,
    })
  }
})

const isSubmitDisabled = computed(
  () => isSubmitted.value && !!Object.keys(errors.value).length,
)
</script>

<template>
  <form
    class="flex flex-col items-center"
    novalidate
    @submit.prevent="signUp"
  >
    <IconLogo
      aria-label="Branded Content Logo"
      class="w-[137px]"
      role="img"
    />
    <h2 class="my-8 text-center text-4xl font-normal capitalize">
      {{ type }} Sign Up
    </h2>
    <div class="flex w-full flex-col items-start">
      <div class="p-float-label w-full">
        <InputText
          id="username"
          v-bind="username"
          type="username"
          :class="['w-full', { 'p-invalid': errors.username && isSubmitted }]"
          @blur="username.modelValue = username.modelValue?.trim()"
        />
        <label for="username">
          <span v-if="type === 'brand'">Username / Brand Name</span>
          <span v-else>Choose a username</span>
        </label>
      </div>
      <small
        v-if="errors.username && isSubmitted"
        class="p-error mt-2 text-left"
      >
        {{ errors.username }}
      </small>
      <div class="p-float-label mt-7 w-full">
        <Password
          id="password"
          v-bind="newPassword"
          :class="[
            'w-full',
            { 'p-invalid': errors.newPassword && isSubmitted },
          ]"
          input-class="w-full"
          :feedback="false"
          toggle-mask
        />
        <label for="password">Choose a password</label>
      </div>
      <small
        v-if="errors.newPassword && isSubmitted"
        class="p-error mt-2 text-left"
      >
        {{ errors.newPassword }}
      </small>
      <div class="p-float-label mt-7 w-full">
        <Password
          id="passwordConfirm"
          v-bind="passwordConfirm"
          :class="[
            'w-full',
            { 'p-invalid': errors.passwordConfirm && isSubmitted },
          ]"
          input-class="w-full"
          :feedback="false"
          toggle-mask
        />
        <label for="passwordConfirm">Confirm password</label>
      </div>
      <small
        v-if="errors.passwordConfirm && isSubmitted"
        class="p-error mt-2 text-left"
      >
        {{ errors.passwordConfirm }}
      </small>
      <Button
        type="submit"
        class="mb-4 mt-6 w-full justify-center [&>.p-button-label]:flex-none"
        :disabled="isSubmitDisabled || authStore.isProcessing"
        :label="!authStore.isProcessing ? 'Join' : 'Loading'"
        :icon="authStore.isProcessing ? 'pi pi-spin pi-spinner' : undefined"
      />
    </div>
    <p
      class="mb-4 flex w-full flex-wrap justify-center border-b border-gray-500 pb-4 font-normal text-gray-100"
    >
      Already have an account?
      <Button
        class="p-button-link ml-1 p-0 font-normal"
        type="submit"
        label="Log In"
        @click="$emit('switch', EContent.SIGN_IN)"
      />
    </p>
    <Button
      class="p-button-outlined mb-4 w-full"
      :label="`Sign Up as a ${oppositeType}`"
      @click="$emit('switch', oppositeContent)"
    />
    <p class="m-0 text-center text-xs font-normal text-gray-200">
      By joing, you agree to our
      <NuxtLink
        to="/terms-of-use"
        @click.prevent="isDialogVisible = false"
        >terms of service</NuxtLink
      >. As well as to receive occasional emails from us.
    </p>
  </form>
</template>
